.home-container {
    width: 100vw;
    min-height: 100vh;

  .row{
    width: 95%;
  }
}
h1{
  font-weight: bold;
}
.cta{
  background-color: none;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.logo{
  width: 300px;
}
  