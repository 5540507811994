// styles/DataCard.scss

.card-container {
    position: relative; /* Posición relativa para que la superposición se aplique correctamente */
    height: 72vh;
    border-radius: 8px;
    background-color: papayawhip;
    overflow: hidden; /* Oculta cualquier contenido que se desborde del contenedor */

    /* Estilo para la superposición */
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3); /* Color oscuro con opacidad */
        z-index: 1; /* Asegura que la superposición esté por encima de la imagen */
    }

    .row {
        position: relative; /* Ajusta la posición de los elementos hijos */
        z-index: 2; /* Asegura que el contenido esté por encima de la superposición */

        h1 {
            font-weight: bold;
            color: white; /* Texto blanco para mayor legibilidad */
        }

        h2 {
            color: white; /* Texto blanco para mayor legibilidad */
        }

        button {
            width: 200px;
            border-radius: 200px;
            height: 40px;
        }
    }
}
